<template>
    <div class="alarm">
        <div class="title">알림</div>
        <div class="top clear">
            <span class="title">새소식</span>
            <button class="all-delete-btn" v-if="alarmList.length > 0" @click="deleteConfrim(null)">전체삭제</button>
        </div>
        <div class="empty" v-if="alarmList.length === 0">
            리스트가 없습니다.
        </div>
        <div v-for="alarm in alarmList" :key="`alarm${alarm.psl_idx}`"  class="list" @click.self="moveDetail(alarm.psl_idx)" v-else>
            <button class="delete-btn" @click="deleteConfrim(alarm.psl_idx)"></button>
            <div class="content" @click="moveDetail(alarm.psl_idx)">
                <div class="title">{{alarm.psl_title}}</div>
                <div class="date">{{reformDate(alarm.created_at)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import VuxAlert from "@/mixins/vux-alert";
    export default {
        name: "AlarmListLayout",
        mixins: [VuxAlert],
        inject: ['alarmDelete'],
        props: {

        },
        data() {
            return {

            }
        },
        computed: {
            ...mapState({
                alarmList: state => state.alarm.list,
            }),
        },
        mounted() {

        },
        methods: {
            reformDate(date) {
                if(!date) {
                    return ''
                }
                return this.$moment(date).format('YYYY.MM.DD HH:mm')
            },
            moveDetail(psl_idx) {
                this.$router.push(`/alarm/${psl_idx}`);
            },
            deleteConfrim(psl_idx=null) {
                this.createConfirm({
                    title: '',
                    content: '정말 삭제하시겠습니까?',
                    confirmText: '확인',
                    cancelText: '취소',
                    confirm: () => {
                        this.alarmDelete(psl_idx);
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>